import {mapState} from "vuex";
import remove from "../../mixins/remove";
import NewsPostModel from './models/news-post.model';

export default {
    name: 'NewsComponent',
    data() {
        return {
            publishDialog: false,
            activePublishing: null,
        }
    },
    computed: {
        ...mapState({
            items: (state) => state.news.items,
        }),
        headers() {
            return [
                {
                    text: 'Photo',
                    value: "image",
                    cellClass: "games__image",
                },
                {text: 'Title', value: "title"},
                {text: 'Publish', value: "published", align: "center",},
                {text: 'Active', value: "active"},
                {
                    text: 'Edit',
                    value: "actions",
                    align: "end",
                },
            ];
        },
    },
    methods: {
        removeItem() {
            this.$store.dispatch("news/removeItem", this.selected);
            this.clearDialog();
        },
        changeActivity(e) {
            let data = new NewsPostModel(e);

            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }

            this.$store.dispatch("news/updateItem", {id: e.id, data: formData});
        },
        publishItem(e) {
            this.activePublishing = e;
            this.publishDialog = true;
        },
        approvePublish() {
            this.$store.dispatch("news/publishItem", this.activePublishing).then(() => {
                this.activePublishing = null;
                this.publishDialog = false;
            });
        }
    },
    created() {
        this.$store.dispatch("news/getItems");
    },
    mixins: [remove],
}
